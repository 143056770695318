import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavBar from './pages/NavBar/NavBar';
import Footer from './pages/Footer/Footer';
import Home from './pages/Home/Home';
import AboutUs from './pages/AboutUs/AboutUs';
import Project from './pages/Project/Project';
import Contact from './pages/Contact/Contact';
import Activities from './pages/Activities/Activities';
import News from './pages/News/News';
import Career from './pages/Career/Career';
import Leadership from './pages/Leadership/Leadership';
import Health from './pages/Health/Health';
import Sustainability from './pages/Sustainability/Sustainability';
import Values from './pages/Values/Values';
import Deliver from "./pages/Deliver/Deliver";
import NotFound from './pages/NotFound/NotFound';
import ProjectPage from './pages/ProjectPage/ProjectPage'; 
import NewsPage from './pages/NewsPage/NewsPage';
import ActivitiesPage from './pages/ActivitiesPage/ActivitiesPage'; 
import Admin from './pages/Admin/Admin';
import AdminProjects from './pages/AdminProjects/AdminProjects';
import AdminNews from './pages/AdminNews/AdminNews';
import AdminActivities from './pages/AdminActivities/AdminActivities';
import AdminHiring from './pages/AdminHiring/AdminHiring';
import AdminSubmission from './pages/AdminSubmission/AdminSubmission';
import AddProjectsPage from './pages/AddProjectsPage/AddProjectsPage';
import AddActivitiesPage from './pages/AddActivitiesPage/AddActivitiesPage';
import AddNewsPage from './pages/AddNewsPage/AddNewsPage';
import AddHiringPage from './pages/AddHiringPage/AddHiringPage';
import EditProjectsPage from './pages/EditProjectsPage/EditProjectsPage';
import EditServicesPage from './pages/EditServicesPage/EditServicesPage';
import EditNewsPage from './pages/EditNewsPage/EditNewsPage';
import EditHiringPage from './pages/EditHiringPage/EditHiringPage';
import EditSubmissionPage from './pages/EditSubmissionPage/EditSubmissionPage';
import Login from './pages/Login/Login';
import PrivateRoute from './pages/PrivateRoute/PrivateRoute';

const AppRoutes = () => {
  return (
    <Router>
      <div>
        <RoutesWithLayout />
      </div>
    </Router>
  );
};

const PublicLayout = ({ children }) => (
  <>
    <NavBar />
    {children}
    <Footer />
  </>
);

const PrivateLayout = ({ children }) => (
  <>
    {children}
  </>
);

const RoutesWithLayout = () => {

  return (
    <>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<PublicLayout><Home /></PublicLayout>} />
        <Route path="/about" element={<PublicLayout><AboutUs /></PublicLayout>} />
        <Route path="/projects" element={<PublicLayout><Project /></PublicLayout>} />
        <Route path="/projects/:id" element={<PublicLayout><ProjectPage /></PublicLayout>} />
        <Route path="/activities" element={<PublicLayout><Activities /></PublicLayout>} />
        <Route path="/activities/:id" element={<PublicLayout><ActivitiesPage /></PublicLayout>} />
        <Route path="/news" element={<PublicLayout><News /></PublicLayout>} />
        <Route path="/news/:id" element={<PublicLayout><NewsPage /></PublicLayout>} />
        <Route path="/contact" element={<PublicLayout><Contact /></PublicLayout>} />
        <Route path="/career" element={<PublicLayout><Career /></PublicLayout>} />
        <Route path="/leadership" element={<PublicLayout><Leadership /></PublicLayout>} />
        <Route path="/health" element={<PublicLayout><Health /></PublicLayout>} />
        <Route path="/sustainability" element={<PublicLayout><Sustainability /></PublicLayout>} />
        <Route path="/values" element={<PublicLayout><Values /></PublicLayout>} />
        <Route path="/deliver" element={<PublicLayout><Deliver /></PublicLayout>} />

        {/* Private Routes */}
        <Route path="/admin" element={<PrivateRoute><PrivateLayout><Admin /></PrivateLayout></PrivateRoute>} />
        <Route path="/admin/projects" element={<PrivateRoute><PrivateLayout><AdminProjects /></PrivateLayout></PrivateRoute>} />
        <Route path="/admin/activities" element={<PrivateRoute><PrivateLayout><AdminActivities /></PrivateLayout></PrivateRoute>} />
        <Route path="/admin/news" element={<PrivateRoute><PrivateLayout><AdminNews /></PrivateLayout></PrivateRoute>} />
        <Route path="/admin/hiring" element={<PrivateRoute><PrivateLayout><AdminHiring /></PrivateLayout></PrivateRoute>} />
        <Route path="/admin/submission" element={<PrivateRoute><PrivateLayout><AdminSubmission /></PrivateLayout></PrivateRoute>} />
        <Route path="/admin/add-projects" element={<PrivateRoute><PrivateLayout><AddProjectsPage /></PrivateLayout></PrivateRoute>} />
        <Route path="/admin/add-activities" element={<PrivateRoute><PrivateLayout><AddActivitiesPage /></PrivateLayout></PrivateRoute>} />
        <Route path="/admin/add-news" element={<PrivateRoute><PrivateLayout><AddNewsPage /></PrivateLayout></PrivateRoute>} />
        <Route path="/admin/add-hiring" element={<PrivateRoute><PrivateLayout><AddHiringPage /></PrivateLayout></PrivateRoute>} />
        <Route path="/admin/projects/:id" element={<PrivateRoute><PrivateLayout><EditProjectsPage /></PrivateLayout></PrivateRoute>} />
        <Route path="/admin/activities/:id" element={<PrivateRoute><PrivateLayout><EditServicesPage /></PrivateLayout></PrivateRoute>} />
        <Route path="/admin/hiring/:id" element={<PrivateRoute><PrivateLayout><EditHiringPage /></PrivateLayout></PrivateRoute>} />
        <Route path="/admin/news/:id" element={<PrivateRoute><PrivateLayout><EditNewsPage /></PrivateLayout></PrivateRoute>} />
        <Route path="/admin/submission/:id" element={<PrivateRoute><PrivateLayout><EditSubmissionPage /></PrivateLayout></PrivateRoute>} />

        {/* Login */}
        <Route path="/login" element={<Login />} />

        {/* 404 Not Found */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default AppRoutes;
