import React from 'react';
import './Project.css';
import ProjectIntro from "../../components/ProjectIntro/ProjectIntro.js";
import ProjectList from "../../components/ProjectList/ProjectList.js";

const Project = () => {
  return (
    <div>
      <ProjectIntro />
      <ProjectList />
    </div>
  );
};

export default Project;
