import React from 'react';
import './Sustainability.css';
import AllIntroTemplate from "../../components/AllIntroTemplate/AllIntroTemplate.js";

import ContentModel from "../../components/ContentModel/ContentModel.js";
import { getStoredData } from "../../json/fetchData.js";

const Sustainability = () => {
  const totalData = getStoredData();
  const data = totalData ? totalData["21"] : {};
  return (
    <div>
      <AllIntroTemplate 
        image={data.Image} 
        title={data.Title} 
        page={totalData ? totalData["0"] : {}}
      />
      <ContentModel data={totalData ? totalData["21"] : {}}/>
    </div>
  );
};

export default Sustainability;
