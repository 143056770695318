import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './ProjectPage.css';
import AllIntroTemplate from "../../components/AllIntroTemplate/AllIntroTemplate.js";
import ProjectDetails from "../../components/ProjectDetails/ProjectDetails.js";
import PhotoSlider from "../../components/PhotoSlider/PhotoSlider.js";
import totalData from "../../json/data.json";

const ProjectPage = () => {
  const { id } = useParams(); // Get the project ID from the URL
  const [projectData, setProjectData] = useState(null); // State to hold the project data
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const response = await fetch(`https://msconstruction.vercel.app/projects/${id}`);
        const data = await response.json();
        console.log(data);
        setProjectData(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching project data:", error);
        setLoading(false);
      }
    };

    fetchProjectData();
  }, [id]);

  if (loading) return <p>Loading...</p>; // Show loading state
  if (!projectData) return <p>Project not found.</p>; // Show error if no data found

  return (
    <div>
      <AllIntroTemplate 
        image={projectData.photo[0].url} 
        title={projectData.title} 
        page={totalData[0]} 
      />
      <ProjectDetails 
  title={projectData.projectsname} // Use 'projectsname' for the project title
  location={projectData.location} // Include location if necessary
  description={projectData.details} // Use 'details' for the project description
  
  keypoints={projectData.keypoints} // Pass the key points array
  stats={projectData.stats} // Pass the stats array
  photos={projectData.photo}
/>

      {/* <PhotoSlider /> */}
    </div>
  );
};

export default ProjectPage;
