import React from 'react';
import './Home.css';
import { getStoredData } from "../../json/fetchData.js";
import HomeIntro from "../../components/HomeIntro/HomeIntro.js";
import LatestNews from "../../components/LatestNews/LatestNews.js";
import Experience from "../../components/Experience/Experience.js";
import MapProjects from "../../components/MapProjects/MapProjects.js";
import OurPresence from '../../components/OurPresence/OurPresence.jsx';


const Home = () => {

  const totalData = getStoredData();
  const data = totalData ? totalData["2"] : {};

  return (
    <div>
       <HomeIntro />
      <LatestNews title={data.NewsTitile} sentence={data.NewsSentence} sentence2={data.NewsSentence2} button={data.NewsButton}/>
      <Experience title={data.ExperienceTitile} title2={data.ExperienceSentence} sentence={data.ExperienceSentence2}/>
      <OurPresence data={data}/>
      {/* // <MapProjects /> */}
    </div>
  );
};

export default Home;
