import React from 'react';
import './ContactOfficeDetails.css'; // Updated CSS file name
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

// Import Leaflet icons (optional, if you need a custom marker)
import L from 'leaflet';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

// Fix for missing default marker icon in React Leaflet
let DefaultIcon = L.icon({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});
L.Marker.prototype.options.icon = DefaultIcon;

const OfficeMapDetails = ({ data }) => {
  const center = {
    lat: parseFloat(data[0].ContactOfficeLat), // Use the latitude of the first office as the center
    lng: parseFloat(data[0].ContactOfficeLong), // Specific location longitude
  };

  const handleMarkerClick = (link) => {
    window.open(link, '_blank'); // Open Google Maps link in a new tab
  };

  return (
    <div className="office-map-container">
      <div className='office-map-section'>
        {data.map((office, index) => (
          <div className='office-left-section' key={index}>
            <a href={office.ContactOfficeAddressLink} target="_blank" rel="noopener noreferrer">
              <h1 className='office-head'>{office.ContactOffice}</h1>
            </a>
            <p className='office-city'>
              {office.ContactOfficeCity}
            </p>
            <a href={office.ContactOfficeAddressLink} target="_blank" rel="noopener noreferrer">
              <p className='office-location'>
                {office.ContactOfficeAddress}
              </p>
            </a>
            <p className='office-contact-number'>
  <a href={`tel:${office.ContactOfficePhone.replace("Phone: ", "")}`}>
    {office.ContactOfficePhone.replace("Phone: ", "")}
  </a>
</p>

          </div>
        ))}

        <div className='office-right-section'>
          <MapContainer center={center} zoom={18} style={{ width: '100%', height: '400px' }}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker
              position={center}
              eventHandlers={{
                click: () => handleMarkerClick(data[0].ContactOfficeAddressLink),
              }}
            >
              <Popup>{data[0].ContactOffice}</Popup>
            </Marker>
          </MapContainer>
        </div>
      </div>
    </div>
  );
};

export default OfficeMapDetails;
