import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ProductList from '../../components/DynamicListGrid/DynamicListGrid';
import totalData from '../../json/data.json'; // Import your JSON file
import { useNavigate } from 'react-router-dom';
import "./AdminActivities.css";

const AdminActivities = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const fields = totalData[19].AdminActivities; // Dynamic fields from JSON

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('https://msconstruction.vercel.app/activities');
        setProducts(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching actvities:', error);
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  if (loading) {
    return <p>Loading activities...</p>;
  }

  return (
    <div className="admin-products">
      <h2>Product List</h2>
      <button onClick={() => navigate('/admin/activities')}>
        Create New Product
      </button>
      <ProductList products={products} fields={fields} redirect={totalData[19].AdminProjectsEdit} deleteApi="https://avenue-website-backend.vercel.app/projects"/>
    </div>
  );
};

export default AdminActivities;

