import React, { useState } from 'react';
import './KeyMembers.css';

const KeyMembers = ({ teamData }) => {
  const [selectedMember, setSelectedMember] = useState(null);

  const openPopup = (member) => {
    setSelectedMember(member);
  };

  const closePopup = () => {
    setSelectedMember(null);
  };

  return (
    <div className="key-members-container">
      <div className="key-members-grid">
        {teamData.map((member, index) => (
          <div key={index} className="key-member-card" onClick={() => openPopup(member)}>
            <div className="key-member-photo">
              <img src={member.Photo} alt={member.Name} className="member-photo" />
            </div>
            <div className="key-member-details">
              <h3>{member.Name}</h3>
              <p>{member.Post}</p>
            </div>
          </div>
        ))}
      </div>

      {selectedMember && (
        <div className={`menu-bar ${selectedMember ? 'active' : ''}`}>
          <button className="close-button" onClick={closePopup}>X</button>
          <img src={selectedMember.Photo} alt={selectedMember.Name} className="menu-photo" />
          <h3>{selectedMember.Name}</h3>
          <p><strong>{selectedMember.Post}</strong></p>
          <p>{selectedMember.Sentence}</p>
        </div>
      )}
    </div>
  );
};

export default KeyMembers;
