import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './LatestNews.css';
import Button from '../Button/Button';

const LatestNews = ({ title, sentence, sentence2,button}) => {
  const [news, setNews] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get('https://msconstruction.vercel.app/news');
        setNews(response.data.slice(0, 5));
      } catch (error) {
        console.error('Error fetching news:', error);
      }
    };

    fetchNews();
  }, []);

  const handleReadMoreClick = (id) => {
    navigate(`/news/${id}`);
  };

  if (!news.length) return <div>Loading...</div>;

  return (
    <div className="latest-news-container">
      <div className="latest-news-grid">
        {/* News About Card */}
        <div className="latest-news-about">
          <h2 className="latest-news-aboutTitle">{title}</h2>
          <h3 className="latest-news-aboutSentence">{sentence}</h3>
          <h4 className="latest-news-aboutSentence2">
          {sentence2}
          </h4>
          <Button onClick={() => navigate('/news')}>{button}</Button>
        </div>

        {/* News Cards */}
        {news.slice(0, 2).map((newsItem) => (
          <div key={newsItem._id} className="news-card">
            <img src={newsItem.photo} alt={newsItem.title} className="news-card-image" />
            <div className="news-card-content">
              <h3>{newsItem.title}</h3>
              <p>{newsItem.details}</p>
              <Button onClick={() => handleReadMoreClick(newsItem._id)}>{newsItem.button}</Button>
            </div>
          </div>
        ))}
      </div>

      {/* Second Row with 3 News Cards */}
      <div className="latest-news-grid">
        {news.slice(2, 5).map((newsItem) => (
          <div key={newsItem._id} className="news-card1">
            <img src={newsItem.photo} alt={newsItem.title} className="news-card-image" />
            <div className="news-card-content">
              <h3>{newsItem.title}</h3>
              <p>{newsItem.details}</p>
              <Button onClick={() => handleReadMoreClick(newsItem._id)}>{newsItem.button}</Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LatestNews;
