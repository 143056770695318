import React from 'react';
import './Values.css';
import AllIntroTemplate from "../../components/AllIntroTemplate/AllIntroTemplate.js";
import AllValues from "../../components/AllValues/AllValues.js";
import ContentModel from "../../components/ContentModel/ContentModel.js";
import { getStoredData } from "../../json/fetchData.js";

const Values = () => {
  const totalData = getStoredData();
  const data = totalData ? totalData["20"] : {};
  return (
    <div>
      <AllIntroTemplate 
        image={data.Image} 
        title={data.Title} 
        page={totalData ? totalData["0"] : {}}
      />
      <ContentModel data={totalData ? totalData["20"] : {}}/>
      <AllValues values={data.values}/>
    </div>
  );
};

export default Values;
