import React from 'react';
import totalData from '../../json/data.json';
import DynamicForm from '../../components/DynamicForm/DynamicForm';

const AddProjectsPage = () => {
  const fields = totalData[19].AdminProjects; // Load fields dynamically
  const apiUrl = 'https://msconstruction.vercel.app/projects'; // API endpoint for creating product
  const successRedirect = '/admin/projects'; // Redirect after successful product creation

  return (
    <div>
      <DynamicForm fields={fields} apiUrl={apiUrl} successRedirect={successRedirect} />
    </div>
  );
};

export default AddProjectsPage;