import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube, FaLinkedinIn, FaPlus, FaArrowUp } from 'react-icons/fa'; // Import FaArrowUp icon

import { getStoredData } from '../../json/fetchData.js';
import Button from '../../components/Button/Button';

const Footer = () => {
  // const footerData = data.find(item => item.page === "footer" && item.id === "1");
  const totalData = getStoredData();
  const footerData = totalData ? totalData["1"] : {};
  const [activeIndex, setActiveIndex] = useState(null);
  // const [email, setEmail] = useState(''); // State to store the email input
  // const [submissionStatus, setSubmissionStatus] = useState(''); // State to display submission status
  const [showScrollButton, setShowScrollButton] = useState(false); // State to show/hide scroll button



  // Function to toggle submenu
  const toggleSubMenu = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Function to check scroll position and show button when footer is in view
  const checkScrollPosition = () => {
    const footer = document.querySelector('.Footer');
    if (footer) {
      const footerTop = footer.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      if (footerTop <= windowHeight) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    }
  };

  // UseEffect to add scroll listener
  useEffect(() => {
    window.addEventListener('scroll', checkScrollPosition);
    return () => window.removeEventListener('scroll', checkScrollPosition);
  }, []);

  return (
    <footer className="Footer">
      <div className="Footer-Above">
        <div className='Footer-AboveContainer'>

          <div className="Footer-Explore">
            <h1 className='Footer-ExploreHeader'>{footerData.footerExploreHeader}</h1>
            <ul className="Footer-NavList">
              {totalData[0].menu.map((item, index) => (
                <li key={index} className="Footer-NavItem">
                  {item.submenu ? (
                    <>
                      <div className="Footer-NavLinkWithIcon" onClick={() => toggleSubMenu(index)}>
                        {item.link !== "na" ? (
                          <Link to={item.link} className="Footer-NavData">
                            {item.name}
                          </Link>
                        ) : (
                          <span className="Footer-NavData">{item.name}</span>
                        )}
                        <FaPlus className={`Footer-Icon ${activeIndex === index ? 'Footer-Icon-rotate-up' : 'Footer-Icon-rotate-down'}`} />
                      </div>
                      <ul className={`Footer-SubMenuList ${activeIndex === index ? 'show' : ''}`}>
                        {item.submenu.map((subItem, subIndex) => (
                          <li key={subIndex} className="Footer-SubMenuItem">
                            {subItem.link !== "na" ? (
                              <Link to={subItem.link} className="Footer-SubMenuLink">
                                {subItem.name}
                              </Link>
                            ) : (
                              <span className="Footer-SubMenuLink">{subItem.name}</span>
                            )}
                          </li>
                        ))}
                      </ul>
                    </>
                  ) : (
                    item.link !== "na" ? (
                      <Link to={item.link} className="Footer-NavData">
                        {item.name}
                      </Link>
                    ) : (
                      <span className="Footer-NavData">{item.name}</span>
                    )
                  )}
                </li>
              ))}
            </ul>
          </div>

          <div className="Footer-Contact">
            <h1 className="Footer-HeaderContact">{footerData.footerContactTitle}</h1>
            <div className="Footer-ContactInfo">
              <p className="Footer-ContactDetails">
                <a href={`tel:${footerData.footercontactNumber}`} className="Footer-ContactEmail">
                  {footerData.footercontactNumber}
                </a>
              </p>
              <p className="Footer-ContactDetails">
                <a href={`mailto:${footerData.footercontactEmail}`} className="Footer-ContactEmail">{footerData.footercontactEmail}</a>
              </p>
              <p className="Footer-ContactDetails">
                <a href={footerData.footercontactAddressLink} className="Footer-ContactEmail">{footerData.footercontactAddress}</a>
              </p>
            </div>

            <div className='Footer-ContactDetailsButton'>
            <Button to={footerData.footerContactButtonLink}>{footerData.footerContactButton}</Button>
            </div>
              

          </div>

        </div>
      </div>

      <div className="Footer-Below">
        <div className="Footer-Policy">
          <p className="Footer-PolicyPrivacy">
            <a href={footerData.footerPolicyPrivacyLink} className="Footer-PolicyLink">
              {footerData.footerPolicyPrivacy}
            </a>
          </p>
          <p className="Footer-PolicyDisclaimer">
            <a href={footerData.footerPolicyDisclaimerLink} className="Footer-PolicyLink">
              {footerData.footerPolicyDisclaimer}
            </a>
          </p>
          <p className="Footer-PolicyTermsOfUse">
            <a href={footerData.footerPolicyTermsOfUseLink} className="Footer-PolicyLink">
              {footerData.footerPolicyTermsOfUse}
            </a>
          </p>
        </div>
        <div className="Footer-BelowLine">
          <div className="Footer-Rights">
            <p className="Footer-AllRights">
              {footerData.footerAllRights}
            </p>
            <p className="Footer-PolicyPrivacy">
              {footerData.footerDesignCredit}{' '}
              <Link to={footerData.footerDesignLink} className='Footer-PolicyPrivacyLink'>{footerData.footerDesignLinkHeader}</Link>
            </p>
          </div>
          <div className="Footer-Logo">
            <img src={footerData.footerLogoImage} alt="OGCS Logo" className="Footer-LogoImage" />
          </div>
        </div>
      </div>
      {showScrollButton && (
        <button className="ScrollToTopButton" onClick={scrollToTop}>
          <FaArrowUp />
        </button>
      )}
    </footer>
  );
};

export default Footer;
