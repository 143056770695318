import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './ProjectList.css';

const ProjectList = () => {
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const { id } = useParams(); // Extract the `id` from the URL

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch('https://msconstruction.vercel.app/projects');
        if (!response.ok) {
          throw new Error('Failed to fetch projects');
        }
        const data = await response.json();

        setProjects(data);

        // Filter projects based on `services` field
        if (id) {
          const filtered = data.filter((project) => project.services === id);

          // If no matches found, show all projects
          if (filtered.length === 0) {
            setFilteredProjects(data);
          } else {
            setFilteredProjects(filtered);
          }
        } else {
          setFilteredProjects(data); // Show all projects if no `id` in the URL
        }
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    fetchProjects();
  }, [id]); // Re-run effect when `id` changes

  const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearch(searchTerm);

    const filtered = projects.filter((project) =>
      project.title.toLowerCase().includes(searchTerm)
    );
    setFilteredProjects(filtered);
  };

  const handleProjectClick = (id) => {
    navigate(`/projects/${id}`);
  };

  return (
    <div className="project-list-container">
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search our projects..."
          value={search}
          onChange={handleSearch}
        />
      </div>

      <div className="project-grid">
        {filteredProjects.map((project) => (
          <div
            key={project._id}
            className="project-card"
            onClick={() => handleProjectClick(project._id)}
          >
            <img
              src={project.photo[0]?.url || 'https://via.placeholder.com/150'}
              alt={project.title}
              className="project-image"
            />
            <div className="project-info">
              <h3 className="project-title">{project.title}</h3>
              <p className="project-location">{project.location}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectList;
