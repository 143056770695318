import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './NewsPage.css';
import AllIntroTemplate from "../../components/AllIntroTemplate/AllIntroTemplate.js";

import ContentModel from "../../components/ContentModel/ContentModel.js";
import totalData from "../../json/data.json";
import NewsModel from '../../components/NewsModel/NewsModel.js';
import News from '../News/News.js';

const NewsPage = () => {
  const { id } = useParams(); // Get the news ID from the URL
  const [newsData, setNewsData] = useState(null); // State to hold the fetched news data
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchNewsData = async () => {
      try {
        const response = await fetch(`https://msconstruction.vercel.app/news/${id}`);
        const data = await response.json();
        setNewsData(data); // Set the fetched data
        setLoading(false);
      } catch (error) {
        console.error("Error fetching news data:", error);
        setLoading(false);
      }
    };

    fetchNewsData();
  }, [id]);

  if (loading) return <p>Loading...</p>; // Display loading message
  if (!newsData) return <p>News article not found.</p>; // Display error if data is empty

  return (
    <div>
      <AllIntroTemplate 
        image={newsData.photo} 
        title={newsData.title} 
        page={totalData[0]} 
      />
      <NewsModel data={newsData}/>
    </div>
  );
};

export default NewsPage;
