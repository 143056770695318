import React from 'react';
import './Career.css';
import AllIntroTemplate from "../../components/AllIntroTemplate/AllIntroTemplate.js";
import CareerHiring from '../../components/CareerHiring/CareerHiring.js';

import ContentModel from "../../components/ContentModel/ContentModel.js";
import { getStoredData } from "../../json/fetchData.js";

const Career = () => {
  const totalData = getStoredData();
  const data = totalData ? totalData["25"] : {};

  return (
    <div>
      <AllIntroTemplate image={data.Image} title={data.Title} page={totalData ? totalData["0"] : {}}/>
      <ContentModel data={totalData ? totalData["25"] : {}}/>
      <CareerHiring/>
    </div>
  );
};

export default Career;
