import React from 'react';
import DynamicEditForm from '../../components/DynamicEditForm/DynamicEditForm.js'; // Import your form component
import totalData from '../../json/data.json'; // Assuming your fields are in a JSON file

const EditNewsPage = () => {
  const fields = totalData[19].AdminNews; // Fetch fields from JSON or source
  const apiUrl = 'https://msconstruction.vercel.app/news'; // API URL for fetching/editing product
  const successRedirect = '/admin/news'; // Where to redirect after a successful update

  return (
    <div>
      <h1 className='edit-header'>Edit news</h1>
      <DynamicEditForm
        fields={fields}
        apiUrl={apiUrl}
        successRedirect={successRedirect}
      />
    </div>
  );
};


export default EditNewsPage;
