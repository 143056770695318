import React, { useState, useEffect } from 'react';
import './CareerHiring.css'; // Import the CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faUser } from '@fortawesome/free-solid-svg-icons';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Button from '../../components/Button/Button';


const CareerHiring = () => {
  const [cards, setCards] = useState([]); // State to store the hiring data
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [loading, setLoading] = useState(true); // State to manage loading
  const [error, setError] = useState(null); // State to handle errors

  const animationControls = useAnimation();
  const [ref, inView] = useInView();

  // Fetch the data from the API when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://msconstruction.vercel.app/hiring');
        if (!response.ok) {
          throw new Error('Failed to fetch hiring data');
        }
        const data = await response.json();
        setCards(data); // Set the fetched data to the state
        setLoading(false); // Stop loading once data is fetched
      } catch (err) {
        setError(err.message); // Handle any errors
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this runs once when the component mounts

  // Animate when the element comes into view
  useEffect(() => {
    if (inView) {
      animationControls.start({
        opacity: 1,
        y: 0,
        transition: { duration: 1 },
      });
    }
  }, [animationControls, inView]);

  const goToPrevCard = () => {
    setCurrentCardIndex((prevIndex) =>
      prevIndex === 0 ? cards.length - 1 : prevIndex - 1
    );
  };

  const goToNextCard = () => {
    setCurrentCardIndex((prevIndex) =>
      prevIndex === cards.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Loading state
  if (loading) {
    return <p>Loading...</p>;
  }

  // Error state
  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <motion.div ref={ref} initial={{ opacity: 0, y: 60 }} animate={animationControls}>
      <h1 className="HiringcardSlider-Heading">CURRENT OPENINGS</h1>

      <div className="Hiringcard-slider">
        <button className="Hiringslider-button left" aria-label="Previous slide" onClick={goToPrevCard}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>

        <div className="Hiringcard-container">
          <div className="Hiringcard-about active">
            <div className="Hiringright-section">
              <div className="Hiringcard-content">
                <h2>{cards[currentCardIndex].position}</h2>
                <p className="HiringParaExperience">
                  Experience: {cards[currentCardIndex].Experience}
                </p>
                <p className="HiringParaExperience">
                  Openings: {cards[currentCardIndex].Openings}
                </p>
                <p className="HiringParaQualification">
                  Qualification: {cards[currentCardIndex].Qualification}
                </p>
                <p className="HiringParaLocation">
                  Location: {cards[currentCardIndex].Location}
                </p>
                <p className="HiringParaDetails">
                  {cards[currentCardIndex].details}
                </p>
                  <Button to="/contact" icon={faUser}>Apply Now</Button>
              </div>
            </div>
          </div>
        </div>

        <button className="Hiringslider-button right" aria-label="Next slide" onClick={goToNextCard}>
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>

      <div className="Hiringdots-container">
        <div className="Hiringdots">
          {cards.map((_, index) => (
            <span
              key={index}
              className={index === currentCardIndex ? 'Hiringdash active' : 'Hiringdash'}
              onClick={() => setCurrentCardIndex(index)}
            />
          ))}
        </div>
      </div>
    </motion.div>
  );
};

export default CareerHiring;
