import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './HomeIntro.css'; // Add your styles here
import Button from '../Button/Button';

const HomeIntro = () => {
  const [projects, setProjects] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [zoomClass, setZoomClass] = useState(false);
  const [progress, setProgress] = useState(0); // State to track the loading progress
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get('https://msconstruction.vercel.app/projects');
        setProjects(response.data);
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    fetchProjects();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setZoomClass(false); // Reset zoom immediately
      setTimeout(() => {
        setCurrentIndex((prevIndex) =>
          prevIndex === projects.length - 1 ? 0 : prevIndex + 1
        );
        setTimeout(() => setZoomClass(true), 100); // Apply zoom after the image changes
      }, 500); // Delay the image change to ensure the reset happens first
    }, 10000); // Change image every 10 seconds

    return () => clearInterval(interval);
  }, [projects]);

  useEffect(() => {
    let progressInterval;
    if (projects.length > 0) {
      setProgress(0); // Reset progress when new image is shown

      progressInterval = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 100) return 100;
          return prevProgress + 1; // Increase progress by 1% every 100ms
        });
      }, 100); // 100ms interval to reach 100% in 10 seconds
    }

    return () => clearInterval(progressInterval);
  }, [currentIndex, projects]);

  const handleButtonClick = (id) => {
    navigate(`/projects/${id}`);
  };

  if (!projects.length) return <div>Loading...</div>;

  // Destructure current project with safety checks for undefined or missing data
  const currentProject = projects[currentIndex];
  const { photo, title, details, state, _id } = currentProject || {};

  // Check if photo and photo.url exist and are valid
  const backgroundImage = photo && photo[0].url ? `url(${photo[0].url})` : '';

  return (
    <div className="home-intro">
      <div
        className={`home-intro-background ${zoomClass ? 'zoom' : ''}`}
        style={{
          backgroundImage: backgroundImage,
        }}
      ></div>
      <div className="home-intro-content">
        <p className="home-intro-contentState">{state || 'No State Information'}</p>
        <h1 className="home-intro-contentTitle">{title || 'No Title Available'}</h1>
        <p className="home-intro-contentDetails">{details || 'No Details Available'}</p>
        <Button onClick={() => handleButtonClick(_id)}>View</Button>

        {/* Progress bar */}
        <div className="progress-bar">
          <div className="progress-bar-fill" style={{ width: `${progress}%` }}></div>
        </div>
      </div>
    </div>
  );
};

export default HomeIntro;
