import React from 'react';
import './News.css';
import AllIntroTemplate from "../../components/AllIntroTemplate/AllIntroTemplate.js";

import NewsList from "../../components/NewsList/NewsList.js";
import totalData from "../../json/data.json";

const News = () => {
  const data = totalData[22];
  return (
    <div>
      <AllIntroTemplate image={data.Image} title={data.Title} page={totalData[0]}/>
      <NewsList />
    </div>
  );
};

export default News;
