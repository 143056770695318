import React from 'react';
import "./Deliver.css";
import ContentModel from "../../components/ContentModel/ContentModel.js";
import AllIntroTemplate from "../../components/AllIntroTemplate/AllIntroTemplate.js";
import { getStoredData } from "../../json/fetchData.js";

const Deliver = () => {
  const totalData = getStoredData();
  const data = totalData ? totalData["27"] : {};
  return (
    <div>
      <AllIntroTemplate 
        image={data.Image} 
        title={data.Title} 
        page={totalData ? totalData["0"] : {}}
      />
      {/* <ContentModel  title={data.AboutHeader} sentence={data.AboutSentence} video={data.AboutVideo} Points={data.AboutPoints} sentence2={data.AboutSentence} /> */}
      <ContentModel data={totalData ? totalData["27"] : {}}/>
    </div>
  )
}

export default Deliver