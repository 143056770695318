import React from 'react';
import './Contact.css';
import AllIntroTemplate from "../../components/AllIntroTemplate/AllIntroTemplate.js";
import ContactUsForm from "../../components/ContactUsForm/ContactUsForm.js";
import OfficeMapDetails from '../../components/ContactOfficeDetails/ContactOfficeDetails.js';
import { getStoredData } from "../../json/fetchData.js";

const Contact = () => {
  const totalData = getStoredData();
  const data = totalData[26];
  return (
    <div>
      <AllIntroTemplate image={data.Image} title={data.Title} page={totalData[0]}/>
      <ContactUsForm data={data.ContactContent}/>
      <OfficeMapDetails data={data.ContactMap}/>
    </div>
  );
};

export default Contact;
