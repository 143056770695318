import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./ActivitiesCard.css";

const ActivitiesCard = () => {
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const response = await fetch("https://msconstruction.vercel.app/activities");
        if (!response.ok) {
          throw new Error("Failed to fetch activities");
        }
        const data = await response.json();
        setActivities(data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchActivities();
  }, []);

  const handleCardClick = (id) => {
    navigate(`/activities/${id}`);
  };

  if (loading) {
    return <div className="activities-loading">Loading activities...</div>;
  }

  if (error) {
    return <div className="activities-error">Error: {error}</div>;
  }

  if (!activities || activities.length === 0) {
    return <div className="activities-no-data">No activities available.</div>;
  }

  return (
    <div className="activities-container">
      {activities.map((activity) => (
        <div
          key={activity._id}
          className="activities-card"
          onClick={() => handleCardClick(activity._id)} // Redirect on click
        >
          <img
            src={activity.photo[0]?.url || "https://via.placeholder.com/150"}
            alt={activity.title}
            className="activities-image"
          />
          <div className="activities-info">
            <h3 className="activities-title">{activity.title}</h3>
            <p className="activities-details">{activity.details}</p>
            <p className="activities-project">Project: {activity.project}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ActivitiesCard;
