import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './NewsList.css';
import Button from '../Button/Button';

const NewsList = () => {
  const [news, setNews] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await fetch('https://msconstruction.vercel.app/news');
        const data = await response.json();
        setNews(data);
      } catch (error) {
        console.error('Error fetching news:', error);
      }
    };
    fetchNews();
  }, []);

  const handleArticleClick = (id) => {
    navigate(`/news/${id}`);
  };

  return (
    <div className="news-list-container">
      {news.map((article) => (
        <div
          className="news-list-card"
          key={article._id}
          onClick={() => handleArticleClick(article._id)}
        >
          <img
            src={article.photo}
            alt={article.title}
            className="news-list-image"
          />
          <div className="news-list-content">
            <h2 className="news-list-title">{article.title}</h2>
            <p className="news-list-date">{new Date(article.date).toDateString()}</p>
            <p className="news-list-details">{article.details}</p>
            <div className='news-list-Button'>
            <Button>{article.button}</Button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default NewsList;
