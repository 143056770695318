import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './ProjectIntro.css';
import Button2 from '../Button/Button2';

const ProjectSlider = () => {
  const [projects, setProjects] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch('https://msconstruction.vercel.app/projects');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();

        // Limit projects to 5
        setProjects(data.slice(0, 5));
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % projects.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? projects.length - 1 : prevIndex - 1
    );
  };

  const handleRedirect = (id) => {
    navigate(`/projects/${id}`);
  };

  if (loading) {
    return <div className="project-slider-loading">Loading project details...</div>;
  }

  if (error) {
    return <div className="project-slider-error">Error: {error}</div>;
  }

  if (!projects || projects.length === 0) {
    return <div className="project-slider-no-data">No project data available.</div>;
  }

  const currentProject = projects[currentIndex];

  return (
    <div
      className="project-slider-container"
      style={{
        '--background-image': `url(${currentProject.photo[0].url})`,
      }}
    >
      <button className="project-slider-button project-slider-prev" onClick={handlePrev}>
        &lt;
      </button>
      <button className="project-slider-button project-slider-next" onClick={handleNext}>
        &gt;
      </button>
      <div className="project-slider">
        <div className="project-slider-wrapper">
          {projects.map((project, index) => (
            <div
              key={index}
              className={`project-slider-slide ${
                index === currentIndex ? 'active' : ''
              }`}
            >
                {/* Left Section */}
                  <img
                    src={project.photo[0].url}
                    alt={project.client}
                    className="project-slider-image"
                  />
                  <p className="project-slider-title">{project.title}</p>
                  <p className="project-slider-client">{project.client}</p>
                  <p className="project-slider-details">{project.details}</p>
                  <p className="project-slider-location">{project.location}</p>
                  <div className='project-slider-Button'>
                  <Button2
                    onClick={() => handleRedirect(project._id)}
                  >
                    {project.projectsname}
                  </Button2>
                  </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectSlider;
