import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './ActivitiesPage.css';
import AllIntroTemplate from "../../components/AllIntroTemplate/AllIntroTemplate.js";
import { getStoredData } from "../../json/fetchData.js";

import ContentModel from "../../components/ContentModel/ContentModel.js";
import ProjectList from "../../components/ProjectList/ProjectList.js";


const ActivitiesPage = () => {
  const totalData = getStoredData();
  const { id } = useParams(); // Get the activity ID from the URL
  const [activitiesData, setActivitiesData] = useState(null); // State to hold the fetched activity data
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchActivitiesData = async () => {
      try {
        const response = await fetch(`https://msconstruction.vercel.app/activities/${id}`);
        const data = await response.json();
        setActivitiesData(data); // Set the fetched data
        setLoading(false);
      } catch (error) {
        console.error("Error fetching activity data:", error);
        setLoading(false);
      }
    };

    fetchActivitiesData();
  }, [id]);

  if (loading) return <p>Loading...</p>; // Display loading message
  if (!activitiesData) return <p>Activity not found.</p>; // Display error if no data is found

  return (
    <div>
      <AllIntroTemplate 
        image={activitiesData.photo[0].url} 
        title={activitiesData.title} 
        page={totalData[0]} 
      />
      <ContentModel data={activitiesData}/>
      <ProjectList />
    </div>
  );
};

export default ActivitiesPage;
