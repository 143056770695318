import React from 'react';
import totalData from '../../json/data.json';
import DynamicForm from '../../components/DynamicForm/DynamicForm';

const AddNewsPage = () => {
  const fields = totalData[19].AdminNews; // Load fields dynamically
  const apiUrl = 'https://msconstruction.vercel.app/news'; // API endpoint for creating product
  const successRedirect = '/admin/News'; // Redirect after successful product creation

  return (
    <div>
      <DynamicForm fields={fields} apiUrl={apiUrl} successRedirect={successRedirect} />
    </div>
  );
};

export default AddNewsPage;
