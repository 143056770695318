import React from 'react'
import "./Activities.css";
import ContentModel from "../../components/ContentModel/ContentModel.js";
import AllIntroTemplate from "../../components/AllIntroTemplate/AllIntroTemplate.js";
import ActivitiesCard from '../../components/ActivitiesCard/ActivitiesCard.js';
import { getStoredData } from "../../json/fetchData.js";

const Activities = () => {
  const totalData = getStoredData();
  const data = totalData ? totalData["28"] : {};
  return (
    <div>
      <AllIntroTemplate image={data.Image} title={data.Title} page={totalData ? totalData["0"] : {}}/>
      <ActivitiesCard/>
      <ContentModel data={totalData ? totalData["28"] : {}}/>
    </div>
  )
}

export default Activities