import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './Experience.css';

const Experience = ({title, title2, sentence}) => {
  const [activities, setActivities] = useState([]);
  const [backgroundImage, setBackgroundImage] = useState('');
  const [hoveredCard, setHoveredCard] = useState(null); // State for the hovered card ID
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 786); // State for screen width

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const response = await axios.get('https://msconstruction.vercel.app/activities');
        setActivities(response.data);
      } catch (error) {
        console.error('Error fetching activities:', error);
      }
    };

    fetchActivities();

    // Event listener for window resize
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 786);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleMouseEnter = (photoUrl, id) => {
    if (isLargeScreen) {
      setBackgroundImage(photoUrl); // Set background image on hover
      setHoveredCard(id); // Set the hovered card ID
    }
  };

  const handleMouseLeave = () => {
    if (isLargeScreen) {
      setBackgroundImage(''); // Reset background on mouse leave
      setHoveredCard(null); // Reset hovered card ID
    }
  };

  return (
    <div
      className="experience-container"
      style={{
        backgroundImage: isLargeScreen && backgroundImage ? `url(${backgroundImage})` : 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        transition: 'background-image 0.5s ease-in-out', // Smooth transition
      }}
    >
      {/* Static Left Section */}
      <div className="experience-left">
        <div className="experience-leftContainer">
          <h2 className="experience-leftContainerTitle">{title}</h2>
          <h3 className="experience-leftContainerTitle2">{title2}</h3>
          <p className="experience-leftContainerSentence">
            {sentence}
          </p>
        </div>    
      </div>

      {/* Dynamic Right Section */}
      <div className="experience-right">
        {activities.map((activity) => (
          <Link
            key={activity.id}
            to={`/activities/${activity._id}`} // Redirect on click
            className="activity-card"
            onMouseEnter={() => handleMouseEnter(activity.photo[0]?.url, activity.id)}
            onMouseLeave={handleMouseLeave}
            style={{
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              transition: 'background-image 0.5s ease-in-out', // Smooth transition
              backgroundImage: isLargeScreen
                ? hoveredCard === activity.id
                  ? `url(${activity.photo[0]?.url})`
                  : 'none'
                : `url(${activity.photo[0]?.url})`, // Show background by default for screens <= 786px
            }}
          >
            <h3 className="activity-cardTitle">{activity.title}</h3>
            <p className="activity-cardDetails">{activity.details}</p>
            <span className="activity-cardProjects">{activity.project}</span>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Experience;
