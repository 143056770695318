import React from 'react';
import './Leadership.css';
import AllIntroTemplate from "../../components/AllIntroTemplate/AllIntroTemplate.js";
import { getStoredData } from "../../json/fetchData.js";
import KeyMembers from "../../components/KeyMembers/KeyMembers.js";

const Leadership = () => {
  const totalData = getStoredData();
  const data = totalData ? totalData["23"] : {};
  return (
    <div>
      <AllIntroTemplate image={data.Image} title={data.Title} page={totalData[0]}/>
      <KeyMembers teamData={data.Team}/>
    </div>
  );
};

export default Leadership;
