import React from 'react';
import totalData from '../../json/data.json';
import DynamicForm from '../../components/DynamicForm/DynamicForm';

const AddHiringPage = () => {
  const fields = totalData[19].AdminHiring; // Load fields dynamically
  const apiUrl = 'https://msconstruction.vercel.app/hiring'; // API endpoint for creating product
  const successRedirect = '/admin/hiring'; // Redirect after successful product creation

  return (
    <div>
      <DynamicForm fields={fields} apiUrl={apiUrl} successRedirect={successRedirect} />
    </div>
  );
};


export default AddHiringPage;
