import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import './ProjectDetails.css';

const ProjectDetails = ({ 
  projectPhoto, 
  title, 
  location,
  description, 
  services, 
  keypoints, 
  stats,
  photos // Array of photo URLs for the slider
}) => {
  return (
    <div className='ProjectDetailsContainer'>
      <div className='ProjectDetailsContent'>

        {/* Title */}
        {title && <h1 className='ProjectTitle'>{title}</h1>}

        {/* Location */}
        {location && (
          <p className='ProjectLocation'>{location}
          </p>
        )}

        {/* Description */}
        {description && (
          <div className='ProjectDescription'>
            {description.split('. ').map((part, idx) => (
              part && <p key={idx}>{part.trim()}.</p>
            ))}
          </div>
        )}

        {/* Services */}
        {services && (
          <p className='ProjectServices'>
            <strong>Services:</strong> {services}
          </p>
        )}

        {/* Key Points */}
        {keypoints && keypoints.length > 0 && (
          <ul className='ProjectKeyPoints'>
            <strong>Key Points:</strong>
            {keypoints.map((point, idx) => (
              <li key={idx}>{point.points}</li>
            ))}
          </ul>
        )}

                {/* Photo Slider */}
{photos && photos.length > 0 && (
  <div className="PhotoSlider">
    <Swiper
      modules={[Navigation, Pagination, Autoplay]}
      spaceBetween={20}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      autoplay={{ delay: 3000 }}
      loop={true}
    >
      {photos.map((photo, idx) => (
        <SwiperSlide key={idx}>
          <img 
            src={photo.url} // Use the 'url' field from the photo object
            alt={`Slide ${idx + 1}`} 
            className="SliderImage" 
          />
        </SwiperSlide>
      ))}
    </Swiper>
  </div>
)}

        {/* Stats */}
        {stats && stats.length > 0 && (
          <div className='ProjectStats'>
            <strong>Stats:</strong>
            {stats.map((stat, idx) => (
              <div key={idx} className='StatItem'>

                  {stat.icon && (
                    <img 
                      src={stat.icon} 
                      alt={`Stat ${idx + 1}`} 
                      className='StatIcon' 
                    />
                  )}
                  <p className='StatNumber'>{stat.points}:</p> 
                  <p className='StatPoints'>{stat.number}</p>

              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectDetails;
