import React from 'react';
import './NewsModel.css';

const NewsModel = ({ data }) => {
  return (
    <div className="NewsModelContainer">
      <div className="NewsModelDetails">

        {/* Render the date */}
        {data.date && (
          <p className="NewsModelDate">{new Date(data.date).toLocaleDateString()}</p>
        )}

        {/* Render the main content */}
        {data.content && (
          <div className="NewsModelContent">
            {data.content.split('. ').map((sentence, index) => (
              <p key={index}>{sentence.trim()}.</p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default NewsModel;
