import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Admin.css';

const Admin = () => {
  const navigate = useNavigate();

  // Check if the user is authenticated
  useEffect(() => {
    const authenticated = localStorage.getItem('authenticated');
    if (!authenticated) {
      navigate('/login'); // Redirect to login if not authenticated
    }
  }, [navigate]);

  const handleRedirect = (path) => {
    navigate(path);
  };

  return (
    <div className="admin-panel">
      <div className="card-container">
        <div className="card-box" onClick={() => handleRedirect('/admin/projects')}>
          <h3>Projects</h3>
          <p>Details about Projects</p>
        </div>
        <div className="card-box" onClick={() => handleRedirect('/admin/activities')}>
          <h3>Activities</h3>
          <p>Details about Activities</p>
        </div>
        <div className="card-box" onClick={() => handleRedirect('/admin/news')}>
          <h3>news</h3>
          <p>Details about News</p>
        </div>
        <div className="card-box" onClick={() => handleRedirect('/admin/hiring')}>
          <h3>Hiring</h3>
          <p>Details about Hiring</p>
        </div>
        <div className="card-box" onClick={() => handleRedirect('/admin/submission')}>
          <h3>Submission</h3>
          <p>Details about Submission</p>
        </div>
      </div>
    </div>
  );
};

export default Admin;
