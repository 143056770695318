import React from 'react';
import totalData from '../../json/data.json';
import DynamicForm from '../../components/DynamicForm/DynamicForm';

const AddActivitiesPage = () => {
  const fields = totalData[19].AdminActivities; // Load fields dynamically
  const apiUrl = 'https://msconstruction.vercel.app/activities'; // API endpoint for creating product
  const successRedirect = '/admin/activities'; // Redirect after successful product creation

  return (
    <div>
      <DynamicForm fields={fields} apiUrl={apiUrl} successRedirect={successRedirect} />
    </div>
  );
};


export default AddActivitiesPage;
