import React from 'react';
import './ContentModel.css';

const ContentModel = ({ data }) => {
  // Check if the video link is from YouTube
  const isYouTube = data.Video && (data.Video.includes('youtube.com') || data.Video.includes('youtu.be'));

  // Format YouTube URL for embedding
  const getYouTubeEmbedUrl = (url) => {
    if (url.includes('watch?v=')) {
      return url.replace('watch?v=', 'embed/');
    } else if (url.includes('youtu.be/')) {
      return url.replace('youtu.be/', 'youtube.com/embed/');
    }
    return url;
  };

  return (
    <div className='ContentModelContainer'>
      <div className='ContentModelDetails'>
        {Object.entries(data).map(([key, content], index) => {
          switch (key) {
            case 'ContentPhoto':
              return <img key={index} src={content} alt={data.Title} className='ContentImage' />;
            case 'Header':
              return <p key={index} className='ContentHeader'>{content}</p>;
            case 'Sentence':
            case 'Sentence2':
              return (
                <div key={index} className='ContentSentence'>
                  {content.split('. ').map((part, idx) => (
                    part && <p key={idx}>{part.trim()}.</p>
                  ))}
                </div>
              );
            case 'Video':
              return (
                <div key={index} className='ContentVideo'>
                  {isYouTube ? (
                    <iframe
                      width="100%"
                      height="500"
                      src={getYouTubeEmbedUrl(content)}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  ) : (
                    <video src={content} controls width="100%" />
                  )}
                </div>
              );
            case 'Points':
              return (
                <ul key={index} className='ContentPointsList'>
                  {content.map((point, idx) => (
                    <li key={idx}>{point.Point}</li>
                  ))}
                </ul>
              );
            case 'cards':
              return (
                <div key={index} className='CardsContainer'>
                  {content.map((card, idx) => (
                    <div key={idx} className='Card'>
                      <img src={card.photo} alt={card.title} className='CardImage' />
                      <div className='CardContent'>
                        <h3 className='CardTitle'>{card.title}</h3>
                        <p className='CardDetails'>{card.details}</p>
                      </div>
                    </div>
                  ))}
                </div>
              );
            default:
              return null;
          }
        })}
      </div>
    </div>
  );
};

export default ContentModel;
