import React from 'react';
import { Link } from 'react-router-dom';
import './AllIntroTemplate.css';

const AllIntroTemplate = ({ image, title, page }) => {
  // Function to generate breadcrumb based on `page` data
  const generateBreadcrumb = (pageData) => {
    const breadcrumb = [];

    // Add 'Home' link
    breadcrumb.push(
      <Link key="home" to="/" className="breadcrumb-link">
        Home
      </Link>
    );

    let breadcrumbGenerated = false;

    // Try generating breadcrumb from page data
    if (pageData && pageData.menu) {
      pageData.menu.forEach((menuItem) => {
        if (
          menuItem.link === window.location.pathname ||
          (menuItem.submenu && menuItem.submenu.some((sub) => sub.link === window.location.pathname))
        ) {
          breadcrumbGenerated = true;

          breadcrumb.push(
            <span key={`${menuItem.name}-separator`}> / </span>,
            <Link key={menuItem.name} to={menuItem.link} className="breadcrumb-link">
              {menuItem.name}
            </Link>
          );

          if (menuItem.submenu) {
            const subItem = menuItem.submenu.find((sub) => sub.link === window.location.pathname);
            if (subItem) {
              breadcrumb.push(
                <span key={`${subItem.name}-separator`}> / </span>,
                <Link key={subItem.name} to={subItem.link} className="breadcrumb-link">
                  {subItem.name}
                </Link>
              );
            }
          }
        }
      });
    }

    // If no breadcrumb was generated from page data, fall back to URL segments
    if (!breadcrumbGenerated) {
      const pathSegments = window.location.pathname.split('/').filter(Boolean);
      let currentPath = '';

      pathSegments.forEach((segment, index) => {
        currentPath += `/${segment}`;
        
        // Replace `_id` with the title if it matches the current segment
        const isId = /^[a-f0-9]{24}$/.test(segment); // Check if segment is a MongoDB ObjectId
        const segmentLabel = isId ? title : decodeURIComponent(segment.replace(/-/g, ' '));

        breadcrumb.push(
          <span key={`${segment}-separator`}> / </span>,
          <Link key={segment} to={currentPath} className="breadcrumb-link">
            {segmentLabel}
          </Link>
        );
      });
    }

    return breadcrumb;
  };

  return (
    <div className="IntroTemplateContainer">
      <img src={image} alt="Background" className="IntroTemplateImage" />
      <div className="IntroTemplateCard">
        <h1 className="IntroTemplateTitle">{title}</h1>
        <nav className="IntroTemplateBreadcrumb">
          {generateBreadcrumb(page)}
        </nav>
      </div>
    </div>
  );
};

export default AllIntroTemplate;
