import React from 'react';
import './Health.css';
import AllIntroTemplate from "../../components/AllIntroTemplate/AllIntroTemplate.js";

import ContentModel from "../../components/ContentModel/ContentModel.js";
import { getStoredData } from "../../json/fetchData.js";

const Health = () => {
  const totalData = getStoredData();
  const data = totalData ? totalData["24"] : {};
  return (
    <div>
      <AllIntroTemplate image={data.Image} title={data.Title} page={totalData ? totalData["0"] : {}}/>
      <ContentModel data={totalData ? totalData["24"] : {}}/>
    </div>
  );
};

export default Health;
